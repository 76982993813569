import React from "react";
import styled from "@emotion/styled";
import { Container, Row, Col } from "react-bootstrap";

import { ListDataContent } from "./models";

const List = styled(({ className }) => {
  const title = ListDataContent()[0];
  const items = ListDataContent()[1];

  return (
    <div className={`${className}`}>
      <Container>
        <Row>
          <Col md="12" xl="12" className="pl-xl-5">
            <div className="title">
              <span
                className="h2"
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
            </div>
          </Col>
          <Col md="12" xl="12">
            <div className="items">
              {items.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    {item.title}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
})`
  background: #f4f7f6;
  padding-top: 50px;

  @media (min-width: 1200px) {
    padding-top: 100px;
  }

  .container {
    position: relative;
  }

  .title {
    margin-bottom: 30px;

    @media (min-width: 768px) {
      padding-top: 32px;
    }

    @media (min-width: 1200px) {
      padding-top: 74px;
    }
  }

  .items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .item {
      background-color: #fff;
      border-left: 10px solid #a1d078;
      color: #141414;
      font-family: "Titillium Web", sans-serif;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      padding: 20px 40px;
      margin-bottom: 16px;
      width: calc(100%);

      @media (min-width: 1200px) {
        font-size: 24px;
        line-height: 36px;
        width: calc(50% - 30px);
      }
    }
  }
`;

export default List;
