import { useStaticQuery, graphql } from "gatsby";

export const AvantageDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allAvantageDataJson } = useStaticQuery(
    graphql`
      query queryAvantageDataContent {
        allAvantageDataJson {
          edges {
            node {
              title
              items {
                title
              }
            }
          }
        }
      }
    `
  );
  return [
    allAvantageDataJson.edges[0].node.title,
    allAvantageDataJson.edges[0].node.items,
  ];
};
