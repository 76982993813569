import React from "react";
import styled from "@emotion/styled";
import { Container, Row, Col } from "react-bootstrap";

import { CannabisCertifiedDataContent } from "./models";

const Certified = styled(({ className }) => {
  const title = CannabisCertifiedDataContent()[0];
  const images = CannabisCertifiedDataContent()[1];

  return (
    <div className={`${className}`}>
      <Container className="">
        <Row>
          <Col>
            <Row className="justify-content-center align-items-center">
              <span
                className="h1"
                dangerouslySetInnerHTML={{ __html: title }}
              ></span>
              <div className="images">
                {images.map((item, index) => {
                  return (
                    <div className="image" key={index}>
                      <img src={item.node.childImageSharp.fluid.srcWebp} />
                    </div>
                  );
                })}
              </div>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
})`
  padding-bottom: 80px;
  padding-top: 40px;
  position: relative;

  @media (min-width: 768px) {
    padding-bottom: 85px;
    padding-top: 50px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .h1 {
    color: #747474;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }

    @media (min-width: 1200px) {
      text-align: left;
      margin-right: 40px;
    }
  }

  .images {
    display: flex;
    margin-top: 15px;

    @media (min-width: 1200px) {
      margin-top: 0;
    }

    .image {
      margin: 0 6px;

      @media (min-width: 768px) {
        margin: 0 7px;
      }

      @media (min-width: 1200px) {
        margin: 0 16px;
      }

      img {
        max-height: 73px;

        @media (min-width: 768px) {
          max-height: 100%;
        }
      }
    }
  }
`;

export default Certified;
