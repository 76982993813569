import React from "react";
import styled from "@emotion/styled";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import BackgroundImage from "gatsby-background-image";

import { BannerDataContent } from "./models";

const Banner = styled(({ className }) => {
  const leftText = BannerDataContent()[0];
  const rightText = BannerDataContent()[1];
  const bottomText = BannerDataContent()[2];
  const BannerBg = BannerDataContent()[3];
  const BannerLogo = BannerDataContent()[4];

  return (
    <section className={className}>
      <Container>
        <div className="banner">
          <BackgroundImage
            className="banner-bg"
            fluid={BannerBg.childImageSharp.fluid}
          />
          <Row className="align-items-center">
            <Col xl={4} className="order-xs-2">
              <div className="banner-left">{leftText}</div>
            </Col>
            <Col xl={4} className="order-xs-1">
              <img src={BannerLogo.childImageSharp.fluid.srcWebp} />
              <div className="d-none d-xl-block banner-bottom">
                <div dangerouslySetInnerHTML={{ __html: bottomText }}></div>
              </div>
            </Col>
            <Col xl={4} className="order-xs-3">
              <div className="banner-right">{rightText}</div>
            </Col>
            <Col xl={12} className="d-xl-none order-xs-4">
              <div className="banner-bottom">
                <div dangerouslySetInnerHTML={{ __html: bottomText }}></div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
})`
  .banner {
    padding: 14px 21px 18px;
    position: relative;
    margin: 0 -15px;

    @media (max-width: 767px) {
      .container {
        padding: 0;
      }
    }

    @media (min-width: 768px) {
      padding: 14px 21px 18px;
      margin: 0 0;
    }

    @media (min-width: 1200px) {
      padding: 14px 85px 18px;
    }

    @media (max-width: 1200px) {
      text-align: center;
    }

    img {
      max-width: 100%;
      margin: 0 auto 21px;

      @media (min-width: 1200px) {
        margin-bottom: 0;
        max-width: auto;
      }
    }

    .banner-bg {
      background-size: cover;
      height: 100%;
      left: 0;
      top: 0;
      position: absolute !important;
      z-index: -1;
      width: 100%;
    }

    .banner-left,
    .banner-right,
    .banner-bottom {
      color: #ffffff;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }

    @media (min-width: 1200px) {
      .banner-left {
        text-align: left;
      }
      .banner-right {
        text-align: right;
      }
    }

    .banner-bottom {
      font-weight: normal;
      padding-top: 21px;

      @media (min-width: 1200px) {
        padding-top: 60px;
      }

      span {
        font-weight: bold;
      }
    }

    img {
      position: relative;
    }
  }
`;

export default Banner;
