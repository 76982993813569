import { useStaticQuery, graphql } from "gatsby";

export const ServiceAreasDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allServiceAreasDataJson, images } = useStaticQuery(
    graphql`
      query queryServiceAreasDataContent {
        allServiceAreasDataJson {
          edges {
            node {
              title
              images_order {
                desktop
                tablet
                mobile
              }
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "cannabis/services" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 185, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );
  return [
    allServiceAreasDataJson.edges[0].node.title,
    allServiceAreasDataJson.edges[0].node.images_order,
    images.edges,
  ];
};
