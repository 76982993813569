import React from "react";
import styled from "@emotion/styled";
import { Container, Row, Col } from "react-bootstrap";

import { AvantageDataContent } from "./models";

const Avantage = styled(({ className }) => {
  const title = AvantageDataContent()[0];
  const items = AvantageDataContent()[1];

  return (
    <div className={`${className}`}>
      <Container className="text-xl-center">
        <span className="h1" dangerouslySetInnerHTML={{ __html: title }}></span>
        <Row>
          <Col xl="6">
            {items.slice(0, 5).map((item, index) => {
              return (
                <div className="item" key={index}>
                  <div>{item.title}</div>
                </div>
              );
            })}
          </Col>
          <Col xl="6">
            {items.slice(5, 11).map((item, index) => {
              return (
                <div className="item" key={index}>
                  <div>{item.title}</div>
                </div>
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
})`
  background: #231e60;
  padding-top: 50px;

  @media (min-width: 1200px) {
    padding-top: 100px;
  }

  .h1 {
    color: #fff;
    font-family: Changa One;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 34px;
    text-align: left;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 51px;
      letter-spacing: 0.02em;
      margin-bottom: 40px;
    }

    @media (min-width: 1200px) {
      text-align: center;
      margin-bottom: 60px;
    }
  }

  .item {
    color: #fff;
    display: flex;
    font-size: 24px;
    line-height: 36px;
    font-family: Changa One;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 34px;
      text-transform: uppercase;
      margin-bottom: 48px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 80px;
    }

    &:before {
      background-color: #a1d078;
      border-radius: 50%;
      content: "";
      display: block;
      height: 12px;
      position: relative;
      top: 10px;
      width: 12px;

      @media (min-width: 1200px) {
        height: 18px;
        width: 18px;
      }
    }

    div {
      padding-left: 18px;
      width: calc(100% - 18px);
    }
  }
`;

export default Avantage;
