import { useStaticQuery, graphql } from "gatsby";

export const StepsDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allStepsDataJson, gallery } = useStaticQuery(
    graphql`
      query queryStepsDataContent {
        allStepsDataJson {
          edges {
            node {
              title
              items {
                title
              }
              bottom {
                bottom_cta
              }
            }
          }
        }
        gallery: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "cannabis/steps" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 1000, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );
  return [
    allStepsDataJson.edges[0].node.title,
    allStepsDataJson.edges[0].node.items,
    allStepsDataJson.edges[0].node.bottom,
    gallery.edges,
  ];
};
