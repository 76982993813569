import React from 'react'
import styled from '@emotion/styled'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import BackgroundImage from 'gatsby-background-image'

import { CannabisHeroDataContent } from './models'

import mobilPhone from '@images/icons/mobil-phone.svg'

const Hero = styled(({ className }) => {
  const mainContent = CannabisHeroDataContent()[0]
  const rightText = CannabisHeroDataContent()[1]
  const heroBottom = CannabisHeroDataContent()[2]
  const heroBgs = CannabisHeroDataContent()[3]

  return (
    <section className={`${className} hero`}>
      <div id="hero" className="scroll-target"></div>
      <Container fluid className="p-0">
        <Row>
          <Col md="6" className="d-md-block d-none hero-left-bg p-0"></Col>
          <Col xl="6" className="p-0">
            <BackgroundImage
              className="hero-bg"
              fluid={heroBgs.childImageSharp.fluid}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xl="7">
            <div className="hero-main-text">
              <h1 dangerouslySetInnerHTML={{ __html: mainContent.title }}></h1>
              <p>{mainContent.content}</p>
              <button 
                name="button" 
                type="button" 
                id="work-request-button-05909048-1d4f-49b4-b5a2-0774cca2aedb" 
                className="embed-button"
              >
                {mainContent.cta}
              </button>
            </div>
          </Col>
          <Col xl="5" className="pr-xl-0">
            <div className="hero-right-text">
              <span className="h2">
                <img src={mobilPhone} alt="mobilPhone" />
                <span>{rightText.title}</span>
              </span>
              <p>{rightText.content}</p>
            </div>
          </Col>
          <Col lx="12">
            <div className="hero-bottom-text text-xl-center">
              <p dangerouslySetInnerHTML={{ __html: heroBottom }}></p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
})`
  background: #63A536;
  position: relative;

  .scroll-target {
    position: absolute;
    top: -200px;
  }

  .hero-left-bg {
    background: #231E60;
  }

  .hero-bg {
    height: 450px;
  }

  .hero-main-text {
    background-color: #fff;
    padding: 30px 17px 30px 20px;
    margin: -59px 0 60px;

    @media (min-width: 768px) {
      margin: -253px 0 40px;
      padding: 60px 60px 60px 60px;
    }

    @media (min-width: 1200px) {
      margin: -400px 0 50px;
    }

    h1 {
      color: #231e60;
      font-family: Changa One;
      font-style: normal;
      font-weight: normal;
      font-size: 38px;
      line-height: 40px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        font-size: 64px;
        line-height: 68px;
        letter-spacing: 0.02em;
      }

      @media (min-width: 1200px) {
        font-size: 80px;
        line-height: 85px;
        margin-bottom: 16px;
      }
    }

    p {
      color: #231e60;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 30px;

      @media (min-width: 1200px) {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 25px;
      }
    }

    button {
      background: #63A536;
      border: none;
      border-radius: 4px;
      color: #fff;
      display: block;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      padding: 16px 48px;
      text-align: center;
      transition: 0.25s;
      width: auto;

      @media (min-width: 1200px) {
        display: inline-block;
      }

      &:hover,
      &:focus {
        box-shadow: inset 0 -5em 0 0 #63a536;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .hero-right-text {
    margin: 0 0 16px;

    @media (min-width: 768px) {
      margin-bottom: 7px;
      text-align: center;
    }

    @media (min-width: 1200px) {
      margin-bottom: 31px;
      margin-top: 40px;
      margin-right: 50px;
      text-align: left;
    }

    .h2 {
      align-items: start;
      color: #ffffff;
      display: flex;
      font-family: Titillium Web !important;
      font-style: normal;
      font-weight: bold;
      font-size: 23px;
      line-height: 36px;
      margin-bottom: 10px;

      @media (min-width: 768px) {
        font-size: 24px;
      }

      img {
        margin-right: 10px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 5px;
      }
    }

    p {
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      padding-left: 32px;

      @media (min-width: 768px) {
        padding: 0 0;
      }

      @media (min-width: 1200px) {
        margin-left: 32px;
      }
    }
  }

  .hero-bottom-text {
    color: #ffffff;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    padding-left: 32px;
    padding-bottom: 80px;
    margin: 0 0 0;

    @media (min-width: 768px) {
      padding-left: 0;
      padding-bottom: 60px;
      margin: 0 60px 0;
    }

    @media (min-width: 1200px) {
      margin: 0 95px 0;
    }

    p{
      margin-bottom: 0;
    }
  }
`

export default Hero
