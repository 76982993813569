import { useStaticQuery, graphql } from 'gatsby'

export const CannabisIntroTextDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allCannabisIntroTextDataJson } = useStaticQuery(
    graphql`
      query queryCannabisIntroTextDataContent {
        allCannabisIntroTextDataJson {
          edges {
            node {
              intro_text {
                title
                subtitle
                button_cta
              }
            }
          }
        }
      }
    `
  )
  return [
    allCannabisIntroTextDataJson.edges[0].node.intro_text.title,
    allCannabisIntroTextDataJson.edges[0].node.intro_text.subtitle,
    allCannabisIntroTextDataJson.edges[0].node.intro_text.button_cta
  ]
}
