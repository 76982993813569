import React, { useContext, useRef } from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import Slider from 'react-slick'

import { CannabisGalleryDataContent } from './models'

import ThemeContext from '@contexts/themeContext'

import PopupGallery from './components/PopupGallery'

const IntroText = styled(({ className }) => {
  const { activeGalleryPopup, togglePopupGallery } = useContext(ThemeContext)

  const title = CannabisGalleryDataContent()[0]
  const bottom = CannabisGalleryDataContent()[1]
  const gallery = CannabisGalleryDataContent()[2]

  const slider = useRef()

  const settings = {
    margin: 15,
    arrows: false,
    dots: false,
    infinite: false,
    speed: 900,
    slidesToShow: 1.2,
    slidesToScroll: 1
  }

  return (
    <div className={`${className}`}>
      <PopupGallery data={gallery} />
      <div className="gallery-area">
        <Container>
          <Row>
            <Col md="12" xl="3" className="d-md-none d-xl-block">
              <div className="title">
                <span
                  className="h2"
                  dangerouslySetInnerHTML={{ __html: title }}
                ></span>
              </div>
            </Col>
            <Col md="12" xl="9" className="pl-xl-5-5 d-none d-md-block">
              <Row className="align-items-center">
                <Col md="5" className="col-md-4-5 d-none d-md-block d-xl-none">
                  <div className="title">
                    <span
                      className="h2"
                      dangerouslySetInnerHTML={{ __html: title }}
                    ></span>
                  </div>
                </Col>
                <Col md="7" xl="6" className="col-md-6-5 pr-xl-0">
                  <button
                    className="image-btn"
                    onClick={() => togglePopupGallery(!activeGalleryPopup, 0)}
                  >
                    <BackgroundImage
                      className="image big"
                      fluid={gallery[0].node.childImageSharp.fluid}
                    >
                      <span></span>
                    </BackgroundImage>
                  </button>
                </Col>
                <Col md="12" xl="6" className="d-none d-md-block">
                  <Row className="m-xs-4">
                    {gallery.map((item, index) => {
                      if (index !== 0 && index < 5) {
                        return (
                          <Col
                            xl="6"
                            md="3"
                            className={`p-xs-0 ${
                              (index - 1) % 2 === 1 ? 'pl-xl-0' : 'p-xl-0'
                            }`}
                            key={index}
                          >
                            <button
                              className="image-btn"
                              onClick={() =>
                                togglePopupGallery(!activeGalleryPopup, index)
                              }
                            >
                              <BackgroundImage
                                className={`${
                                  index % 2 === 0 ? 'ml-auto' : ''
                                } image normal`}
                                fluid={item.node.childImageSharp.fluid}
                              >
                                <span></span>
                              </BackgroundImage>
                            </button>
                          </Col>
                        )
                      } else {
                        return false
                      }
                    })}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        <div className="d-md-none">
          <Slider ref={slider} {...settings}>
            {gallery.map((item, index) => {
              return (
                <div className="slide-item" key={index}>
                  <button
                    className="image-btn"
                    onClick={() =>
                      togglePopupGallery(!activeGalleryPopup, index)
                    }
                  >
                    <BackgroundImage
                      className="slide-image"
                      fluid={item.node.childImageSharp.fluid}
                    >
                      <span></span>
                    </BackgroundImage>
                  </button>
                </div>
              )
            })}
          </Slider>
        </div>
        <Container>
          <Col md="12">
            <div className="bottom-area">
              <button
                onClick={() => {
                  document.getElementsByClassName('embed-button')[0].click()
                }}
              >
                {bottom.bottom_cta}
              </button>
            </div>
          </Col>
        </Container>
      </div>
    </div>
  )
})`
  background: #f4f7f6;
  padding-bottom: 50px;
  padding-top: 60px;

  @media (min-width: 768px) {
    padding-bottom: 68px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 100px;
    padding-top: 120px;
  }

  .container {
    position: relative;
  }

  .title {
    .h2 {
      margin-bottom: 30px;

      @media (max-width: 767px) {
        width: 92%;
      }

      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 51px;
        margin-bottom: 40px;
      }

      @media (min-width: 1200px) {
        font-size: 32px;
        line-height: 34px;
      }
    }

    @media (min-width: 1200px) {
      padding-right: 25px;
    }
  }

  .image-btn {
    background: transparent;
    border: none;
    display: block;
    padding: 0 !important;
    margin: 0 !important;
    width: 100%;

    span {
      bottom: -6px;
      border: 3px solid #63a536;
      content: '';
      display: none;
      left: -6px;
      top: -6px;
      transition: all 0.5s ease;
      right: -6px;
      position: absolute;
    }

    &:hover {
      span {
        display: block;
      }
    }
  }

  .gallery-area {
    .image {
      background-size: cover;
      background-position: center center;
      margin-bottom: 20px;
      position: relative;
      width: 100%;

      &.big {
        height: 345px;

        @media (min-width: 768px) {
          height: 325px;
        }

        @media (min-width: 1200px) {
          height: 400px;
          margin-left: auto;
          margin-right: 5px;
          width: calc(100% - 5px);
        }
      }

      &.normal {
        height: 164px;
        margin: 0 auto;
        width: calc(100% - 8px);

        @media (min-width: 768px) {
          height: 152px;
        }

        @media (min-width: 1200px) {
          height: 190px;
          margin-bottom: 20px;
          width: calc(100% - 10px);
        }
      }
    }
  }

  .bottom-area {
    padding: 28px 0 0;

    @media (min-width: 1200px) {
      padding-top: 52px;
    }

    p {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }

    button {
      background: #63a536;
      border-radius: 4px;
      border: none;
      color: #fff;
      display: block;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      padding: 16px 48px;
      text-align: center;
      text-transform: uppercase;
      transition: 0.25s;
      margin: 0 auto;

      &:hover,
      &:focus {
        box-shadow: inset 0 -5em 0 0 #231e60;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .slide-item {
    .slide-image {
      background-size: cover;
      background-position: center center;
      height: 400px;
      position: relative;
      width: 100%;
    }
  }

  .slick-slider .slick-track .slick-slide > div {
    background: transparent;
    width: 100%;
  }

  .slick-slide {
    padding: 0 10px;
  }

  .slick-list {
    margin: 0 0 0 24px;
  }
`

export default IntroText
