import { useStaticQuery, graphql } from 'gatsby'

export const ListDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allListDataJson } = useStaticQuery(
    graphql`
      query queryListDataContent {
        allListDataJson {
          edges {
            node {
              title
              items {
                title
              }
            }
          }
        }
      }
    `
  )
  return [
    allListDataJson.edges[0].node.title,
    allListDataJson.edges[0].node.items
  ]
}
