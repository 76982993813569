import React from "react";
import styled from "@emotion/styled";
import { Container, Row, Col } from "react-bootstrap";

import { StepsDataContent } from "./models";

const Avantage = styled(({ className }) => {
  const title = StepsDataContent()[0];
  const items = StepsDataContent()[1];
  const bottom = StepsDataContent()[2];
  const images = StepsDataContent()[3];

  return (
    <div className={`${className}`}>
      <Container className="text-xl-center">
        <span className="h1" dangerouslySetInnerHTML={{ __html: title }}></span>
        <div className="items">
          {items.map((item, index) => {
            return (
              <div className="item" key={index}>
                <span>STEP {index + 1}</span>
                <img src={images[index].node.childImageSharp.fluid.srcWebp} />
                <div>
                  <span>STEP {index + 1}</span>
                  {item.title}
                </div>
              </div>
            );
          })}
        </div>
        <div className="bottom-area">
          <button
            onClick={() => {
              document.getElementsByClassName("embed-button")[0].click();
            }}
          >
            {bottom.bottom_cta}
          </button>
        </div>
      </Container>
    </div>
  );
})`
  .h1 {
    color: #231e60;
    font-family: Changa One;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 51px;
      letter-spacing: 0.02em;
      margin-bottom: 40px;
    }

    @media (min-width: 1200px) {
      text-align: center;
      margin-bottom: 60px;
    }
  }

  .item {
    align-items: center;
    background: #f4f7f6;
    display: flex;
    padding: 12px;
    margin-bottom: 8px;

    @media (min-width: 768px) {
      padding: 30px;
    }

    > span {
      display: none;

      @media (min-width: 768px) {
        display: block;
      }
    }

    span {
      color: #747474;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
    }

    img {
      margin: 0 24px 0 0;

      @media (min-width: 768px) {
        margin: 0 40px;
      }
    }

    div {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;

      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 36px;
      }

      span {
        display: block;
        font-size: 16px;
        line-height: 24px;

        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }

  .bottom-area {
    padding: 28px 0 0;

    @media (min-width: 1200px) {
      padding-top: 52px;
    }

    p {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
    }

    button {
      background: #63a536;
      border-radius: 4px;
      border: none;
      color: #fff;
      display: block;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      padding: 16px 48px;
      text-align: center;
      text-transform: uppercase;
      transition: 0.25s;
      margin: 0 auto 50px;

      @media (min-width: 768px) {
        margin-bottom: 68px;
      }

      @media (min-width: 1200px) {
        margin-bottom: 100px;
      }

      &:hover,
      &:focus {
        box-shadow: inset 0 -5em 0 0 #231e60;
        color: #fff;
        text-decoration: none;
      }
    }
  }
`;

export default Avantage;
