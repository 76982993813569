import { useStaticQuery, graphql } from "gatsby";

export const BannerDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allBannerDataJson, BannerImg, BannerLogo } = useStaticQuery(
    graphql`
      query queryBannerDataContent {
        allBannerDataJson {
          edges {
            node {
              left_text
              right_text
              bottom_text
            }
          }
        }
        BannerImg: file(relativePath: { eq: "cannabis/banner-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 868, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        BannerLogo: file(relativePath: { eq: "cannabis/banner-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 868, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  return [
    allBannerDataJson.edges[0].node.left_text,
    allBannerDataJson.edges[0].node.right_text,
    allBannerDataJson.edges[0].node.bottom_text,
    BannerImg,
    BannerLogo,
  ];
};
