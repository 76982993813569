import React from 'react'
import styled from '@emotion/styled'
import { Row, Col, Container } from 'react-bootstrap'

import { CannabisFormAreaDataContent } from './models'

import FormBox from './components/FormBox'
import Map from './components/Map'

const FormArea = styled(({ className }) => {
  const formData = CannabisFormAreaDataContent()[0]

  return (
    <div className={className}>
      <div id="contact-us" className="scroll-target"></div>
      <Container className="container-fluid-left">
        <Row>
          <Col xs="12" className="p-0" xl={{ span: 6 }}>
            <Map />
          </Col>
          <Col xs="12" xl={{ span: 6 }}>
            <div className="formBox">
              <span className="h1">{formData.title}</span>
              <p dangerouslySetInnerHTML={{ __html: formData.content }}></p>
              <FormBox />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
})`
  background: #f4f7f6;
  padding: 0 0 0 0;
  position: relative;

  @media (min-width: 1200px) {
    padding: 0 0 0 0;
  }

  .scroll-target {
    position: absolute;
    top: -120px;
  }

  .container {
    @media (min-width: 1200px) {
      max-width: none;
    }
  }

  .formBox {
    padding-top: 37px;

    @media (min-width: 768px) {
      padding-top: 50px;
    }

    @media (min-width: 1200px) {
      padding: 40px 40px 0;
    }

    .h1 {
      color: #231e60;
      font-family: Changa One;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 34px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        font-size: 48px;
        line-height: 51px;
      }
    }

    p {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
      width: 90%;

      @media (min-width: 768px) {
        width: 100%;
      }
    }
  }
`

export default FormArea
