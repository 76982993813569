import { useStaticQuery, graphql } from "gatsby";

export const StrategyDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allStrategyDataJson, bgImg, images } = useStaticQuery(
    graphql`
      query queryStrategyDataContent {
        allStrategyDataJson {
          edges {
            node {
              title
              items {
                text
              }
            }
          }
        }
        bgImg: file(relativePath: { eq: "cannabis/strategy-bg.png" }) {
          childImageSharp {
            fluid(maxWidth: 868, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "cannabis/strategy" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 1000, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );
  return [
    allStrategyDataJson.edges[0].node.title,
    allStrategyDataJson.edges[0].node.items,
    bgImg,
    images.edges,
  ];
};
