import { useStaticQuery, graphql } from 'gatsby'

export const CannabisGalleryDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allCannabisGalleryDataJson, gallery } = useStaticQuery(
    graphql`
      query queryCannabisGalleryDataContent {
        allCannabisGalleryDataJson {
          edges {
            node {
              title
              bottom {
                bottom_cta
              }
            }
          }
        }
        gallery: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "cannabis/gallery" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 1000, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  )
  return [
    allCannabisGalleryDataJson.edges[0].node.title,
    allCannabisGalleryDataJson.edges[0].node.bottom,
    gallery.edges
  ]
}
