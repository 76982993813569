import React from 'react'
import styled from '@emotion/styled'

// components
import Gallery from './components/Gallery'
import List from './components/List'

const SectionArea = styled(({ className }) => {
  return (
    <div className={className}>
      <div id="why-us" className="scroll-target"></div>
      <List />
      <Gallery />
    </div>
  )
})`
  position: relative;

  .scroll-target {
    position: absolute;
    top: -120px;
  }
`

export default SectionArea
