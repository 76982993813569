import React from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'

import { BeforeAndAfterDataContent } from './models'

import Compare from '@images/cannabis/compare.svg'
import Before from '@images/cannabis/before.svg'
import After from '@images/cannabis/after.svg'

const BeforeAndAfter = styled(({ className }) => {
  const title = BeforeAndAfterDataContent()[0]
  const subtitle = BeforeAndAfterDataContent()[1]
  const items = BeforeAndAfterDataContent()[2]
  const images = BeforeAndAfterDataContent()[3]

  return (
    <div className={`${className}`}>
      <Container className="text-xl-center">
        <span className="h1" dangerouslySetInnerHTML={{ __html: title }}></span>
        <p>{subtitle}</p>
        <div className="items">
          {items.map((item, index) => {
            return (
              <div className="item" key={index}>
                {item.title}
              </div>
            )
          })}
        </div>
        <div className="position-relative">
          <div className="compare">
            <img src={Compare} alt="Compare" />
          </div>

          <Row>
            <Col xs="6" className="p-xs-0 pr-0">
              <BackgroundImage
                className="image"
                fluid={images[0].node.childImageSharp.fluid}
              >
                <span></span>
              </BackgroundImage>
              <div className="alt-text">
                <img src={Before} alt="Before" />
                <span>Before</span>
              </div>
            </Col>
            <Col xs="6" className="p-xs-0 pl-0">
              <BackgroundImage
                className="image last"
                fluid={images[1].node.childImageSharp.fluid}
              >
                <span></span>
              </BackgroundImage>
              <div className="alt-text">
                <img src={After} alt="After" />
                <span>After</span>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
})`
  background: #f4f7f6;
  padding-bottom: 50px;
  padding-top: 50px;

  @media (min-width: 1200px) {
    padding-bottom: 100px;
    padding-top: 100px;
  }

  .h1 {
    color: #141414;
    font-family: Changa One;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 34px;
    text-align: left;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 51px;
      letter-spacing: 0.02em;
      margin-bottom: 20px;
    }
  }

  p {
    color: #141414;
    font-size: 16px;
    font-family: 'Titillium Web', sans-serif;
    line-height: 24px;
    text-align: left;
    margin-bottom: 48px;
  }

  .items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 56px;

    @media (min-width: 768px) {
      margin-bottom: 80px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 40px;
    }

    .item {
      background-color: #fff;
      border-left: 10px solid #a1d078;
      color: #141414;
      font-family: 'Titillium Web', sans-serif;
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      padding: 7px 40px;
      margin-bottom: 16px;
      text-align: left;
      width: 100%;

      @media (min-width: 768px) {
        font-size: 24px;
        line-height: 36px;
        padding: 25px 40px;
      }

      @media (min-width: 1200px) {
        padding: 20px 40px;
      }

      &:last-child {
        margin: 0;
      }
    }
  }

  .compare {
    align-items: center;
    background: #f4f7f6;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: calc((208px - 52px) / 2);
    left: 50%;
    transform: translateX(-50%);
    z-index: 5;
    height: 52px;
    width: 52px;

    img {
      width: 26px;

      @media (min-width: 768px) {
        width: 48px;
      }

      @media (min-width: 1200px) {
        width: 79px;
      }
    }

    @media (min-width: 768px) {
      height: 96px;
      width: 96px;
      top: calc((289px - 96px) / 2);
    }

    @media (min-width: 1200px) {
      height: 157px;
      width: 157px;
      top: calc((474px - 157px) / 2);
    }
  }

  .image {
    background-size: cover;
    background-position: center center;
    margin-bottom: 0;
    position: relative;
    height: 208px;
    width: calc(100% - 4px);

    @media (min-width: 768px) {
      height: 289px;
    }

    @media (min-width: 1200px) {
      height: 474px;
    }

    &.last {
      margin-left: auto;
    }
  }

  .alt-text {
    position: relative;
    text-align: center;
    margin-top: -26px;

    span {
      display: block;
      font-size: 20px;
      font-family: 'Titillium Web', sans-serif;
      line-height: 32px;
      margin-top: 20px;
    }
  }
`

export default BeforeAndAfter
