import React from 'react'
import styled from '@emotion/styled'
import { Container, Row, Col } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'

import { StrategyDataContent } from './models'

const Strategy = styled(({ className }) => {
  const title = StrategyDataContent()[0]
  const items = StrategyDataContent()[1]
  const bgImg = StrategyDataContent()[2]
  const images = StrategyDataContent()[3]

  return (
    <div className={`${className}`}>
      <div id="our-strategy" className="scroll-target"></div>
      <BackgroundImage className="bg" fluid={bgImg.childImageSharp.fluid} />
      <Container className="">
        <span className="h1" dangerouslySetInnerHTML={{ __html: title }}></span>
        <Row>
          {items.map((item, index) => {
            return (
              <Col xl="6" key={index}>
                <div className="item">
                  <BackgroundImage
                    className="item-image"
                    fluid={images[index].node.childImageSharp.fluid}
                  />
                  <div className="item-text">{item.text}</div>
                </div>
              </Col>
            )
          })}
        </Row>
      </Container>
    </div>
  )
})`
  padding-top: 40px;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 50px;
  }

  @media (min-width: 1200px) {
    padding-top: 80px;
  }

  .scroll-target {
    position: absolute;
    top: -120px;
  }

  .bg {
    height: calc(100% - 216px);
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;

    @media (min-width: 768px) {
      height: calc(100% - 106px);
    }

    @media (min-width: 1200px) {
      height: calc(100% - 105px);
    }
  }

  .h1 {
    color: #fff;
    font-family: Changa One;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 34px;
    text-align: left;
    letter-spacing: 0.02em;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 32px;

    @media (min-width: 768px) {
      font-size: 48px;
      line-height: 51px;
      letter-spacing: 0.02em;
      margin-bottom: 40px;
    }

    @media (min-width: 1200px) {
      margin-bottom: 60px;
    }
  }

  .item {
    background: #fff;
    display: block;
    padding: 30px;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      align-items: center;
      display: flex;
    }

    .item-image {
      height: 180px;
      margin-bottom: 16px;

      @media (min-width: 768px) {
        height: 186px;
        margin-bottom: 0;
        width: 180px;
      }
    }

    .item-text {
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;

      @media (min-width: 768px) {
        padding: 9px 0;
        padding-left: 16px;
        width: calc(100% - 180px);
      }
    }
  }
`

export default Strategy
