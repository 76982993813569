import { useStaticQuery, graphql } from 'gatsby'

export const CannabisHeroDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allCannabisHeroDataJson, heroImg } = useStaticQuery(
    graphql`
      query queryCannabisHeroDataContent {
        allCannabisHeroDataJson {
          edges {
            node {
              main_text {
                title
                content
                cta
              }
              right_text {
                title
                content
              }
              hero_bottom_text
            }
          }
        }
        heroImg: file(relativePath: { eq: "cannabis/hero-bg.jpeg" }) {
          childImageSharp {
            fluid(maxWidth: 868, quality: 75) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )
  return [
    allCannabisHeroDataJson.edges[0].node.main_text,
    allCannabisHeroDataJson.edges[0].node.right_text,
    allCannabisHeroDataJson.edges[0].node.hero_bottom_text,
    heroImg
  ]
}
