import React from "react";
import styled from "@emotion/styled";
import { Container, Row } from "react-bootstrap";

import { ServiceAreasDataContent } from "./models";

const ServiceAreas = styled(({ className }) => {
  const title = ServiceAreasDataContent()[0];
  const images_order = ServiceAreasDataContent()[1];
  const images = ServiceAreasDataContent()[2];

  return (
    <div className={`${className}`}>
      <Container className="">
        <Row className="justify-content-center align-items-center">
          <span
            className="h1"
            dangerouslySetInnerHTML={{ __html: title }}
          ></span>
          <div className="images">
            {images.map((item, index) => {
              return (
                <div
                  className={`image order-xl-${
                    images_order[index].desktop
                  } order-md-${images_order[index].tablet} order-xs-${
                    images_order[index].mobile
                  } ${
                    images_order[index].mobile == 5 ||
                    images_order[index].mobile == 6
                      ? "full"
                      : ""
                  }`}
                  key={index}
                >
                  <img src={item.node.childImageSharp.fluid.srcWebp} />
                </div>
              );
            })}
          </div>
        </Row>
      </Container>
    </div>
  );
})`
  padding-bottom: 80px;
  padding-top: 40px;
  position: relative;

  @media (min-width: 768px) {
    padding-bottom: 85px;
    padding-top: 50px;
  }

  @media (min-width: 1200px) {
    padding-bottom: 100px;
    padding-top: 80px;
  }

  .h1 {
    color: #747474;
    font-family: Titillium Web;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 10px;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }

    @media (min-width: 1200px) {
      margin-right: 40px;
    }
  }

  .images {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 28px;

    .image {
      margin: 0 16px 28px;
      text-align: center;
      width: 25%;

      @media (min-width: 768px) {
        width: 15%;
      }

      @media (min-width: 1200px) {
        width: auto;
      }

      @media (max-width: 1199px) {
        &.full {
          width: 40%;
        }
      }

      @media (max-width: 767px) {
        &.full {
          width: 100%;
        }
      }
    }
  }
`;

export default ServiceAreas;
