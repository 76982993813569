import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Form, Button } from 'react-bootstrap'
import Cookies from 'js-cookie'
import publicIp from 'public-ip'
import axios from 'axios'

import successIcon from '@images/icons/success.svg'

axios.defaults.headers.common['Content-Type'] = 'application/json'

const PORTAL_ID = 9020954
const FORM_ID = '308a89a0-3017-47ba-a832-bd8bfa46e811'

const SUCCESS = 'success'
const INFO = 'info'
const ERROR = 'danger'
let tmr = null

const FormBox = styled(({ className }) => {
  const [countryDialCode, setCountryDialCode] = useState('+1')

  const schema = Yup.object({
    FirstName: Yup.string()
      .required('This field is required')
      .matches(
        /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        'Please do not include numbers'
      ),
    LastName: Yup.string()
      .required('This field is required')
      .matches(
        /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
        'Please do not include numbers'
      ),
    email: Yup.string()
      .email('Please enter a valid email address')
      .required('This field is required'),
    phoneNumber: Yup.string().test(
      'is-required',
      'This field is required',
      (value) => {
        if (typeof value === 'undefined') {
          return false
        }
        const newValue = value.replace(countryDialCode, '')
        if (newValue === '') {
          return false
        }
        return true
      }
    ),
    industry: Yup.string().required('This field is required'),
    industryOther: Yup.string().when('industry', {
      is: 'Other',
      then: Yup.string()
        .required('This field is required')
        .matches(
          /^([A-Za-z]+ )+[A-Za-z]+$|^[A-Za-z]+$/,
          'Please do not include numbers'
        )
    }),
    notes: Yup.string()
  })

  const [message, setMessage] = useState({
    type: SUCCESS,
    text: '',
    show: false
  })
  const [ipAddress, setIpAddress] = useState('')

  const getClientIp = async () => {
    const ip = await publicIp.v4()
    setIpAddress(ip)
  }

  useEffect(() => {
    return () => hideMessage(tmr)
  }, [])

  useEffect(() => {
    getClientIp()
  }, [ipAddress])

  function hideMessage() {
    clearTimeout(tmr)
    setMessage({
      show: false
    })
  }
  function showMessage(text, type = INFO) {
    setMessage({
      type,
      text,
      show: true
    })
    tmr = setTimeout(() => {
      hideMessage()
    }, 10000)
  }

  const handleSubmit = (model, { setSubmitting, resetForm }) => {
    showMessage('Sending message...')

    const {
      FirstName,
      LastName,
      email,
      phoneNumber,
      industryOther,
      notes
    } = model

    let { industry } = model

    if (industry === 'Other') {
      industry = industryOther
    }

    const isBrowser = typeof window !== 'undefined'
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`

    const body = {
      fields: [
        {
          name: 'firstname',
          value: FirstName
        },
        {
          name: 'lastname',
          value: LastName
        },
        {
          name: 'email',
          value: email
        },
        {
          name: 'phone',
          value: phoneNumber
        },
        {
          name: 'industry',
          value: industry
        },

        {
          name: 'message',
          value: notes
        }
      ],
      skipValidation: true,
      context: {
        hutk: hutk,
        pageUri: pageUri,
        pageName: pageName,
        ipAddress: ipAddress
      }
    }

    axios
      .post(postUrl, body)
      .then((res) => {
        if (res.status === 200) {
          resetForm()
          showMessage('Message successfully sent!')
        }
      })
      .catch((err) => {
        setSubmitting(false)
        if (err) {
          showMessage(
            'Sorry, but something went wrong. Please try again',
            ERROR
          )
          return console.error(err)
        }
      })
  }

  return (
    <div className={className}>
      <Formik
        validationSchema={schema}
        // eslint-disable-next-line no-console
        onSubmit={handleSubmit}
        initialValues={{
          FirstName: '',
          LastName: '',
          email: '',
          phoneNumber: '',
          industry: '',
          industryOther: '',
          notes: ''
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          isSubmitting,
          errors,
          touched
        }) => (
          <Form
            className={message.show ? 'd-none' : null}
            noValidate
            onSubmit={handleSubmit}
          >
            <div className="row">
              <Form.Group className="form-group col-sm-6 short-input-right">
                <Form.Label className="label-text">First Name</Form.Label>
                <Form.Control
                  type="text"
                  name="FirstName"
                  value={values.FirstName}
                  onChange={handleChange}
                  isInvalid={errors.FirstName && touched.FirstName}
                />
                {errors.FirstName && touched.FirstName ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.FirstName}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
              <Form.Group className="form-group col-sm-6 short-input-left">
                <Form.Label className="label-text">Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="LastName"
                  value={values.LastName}
                  onChange={handleChange}
                  isInvalid={errors.LastName && touched.LastName}
                />
                {errors.LastName && touched.LastName ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.LastName}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            </div>
            <Form.Group className="form-group">
              <Form.Label className="label-text">Email Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="email@email.com"
                name="email"
                value={values.email}
                onChange={handleChange}
                isInvalid={errors.email && touched.email}
              />
              {errors.email && touched.email ? (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="label-text">Phone Number</Form.Label>
              <PhoneInput
                country="us"
                autoFormat={false}
                countryCodeEditable={false}
                enableLongNumbers={true}
                placeholder="Enter phone number"
                inputProps={{ name: 'phoneNumber' }}
                containerClass={
                  errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''
                }
                inputClass={
                  errors.phoneNumber && touched.phoneNumber ? 'is-invalid' : ''
                }
                onChange={(value, country, e, formattedValue) => {
                  e.persist()
                  setTimeout(() => {
                    if (typeof e.target.value !== 'undefined') {
                      setCountryDialCode(`+${country.dialCode}`)
                      handleChange(e)
                    }
                  }, 100)
                }}
                isValid={!(errors.phoneNumber && touched.phoneNumber)}
                value={values.phoneNumber}
              />
              {errors.phoneNumber && touched.phoneNumber ? (
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label className="label-text">Industry</Form.Label>
              <Form.Control
                as="select"
                name="industry"
                value={values.industry}
                onChange={handleChange}
                isInvalid={errors.industry && touched.industry}
              >
                <option value="">Select one option</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Retail">Retail</option>
                <option value="Education">Education</option>
                <option value="Live Events">Live Events</option>
                <option value="Other">Other</option>
              </Form.Control>
              {errors.industry && touched.industry ? (
                <Form.Control.Feedback type="invalid">
                  {errors.industry}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            {values.industry === 'Other' ? (
              <Form.Group className="form-group">
                <Form.Label className="label-text">Other</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Please specify Other Industy"
                  name="industryOther"
                  value={values.industryOther}
                  onChange={handleChange}
                  isInvalid={errors.industryOther && touched.industryOther}
                />
                {errors.industryOther && touched.industryOther ? (
                  <Form.Control.Feedback type="invalid">
                    {errors.industryOther}
                  </Form.Control.Feedback>
                ) : null}
              </Form.Group>
            ) : null}
            <Form.Group className="form-group">
              <Form.Label className="label-text">
                Notes <span>- Optional</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Tell us about your request"
                name="notes"
                value={values.notes}
                onChange={handleChange}
                isInvalid={errors.notes && touched.notes}
              />
              {errors.notes && touched.notes ? (
                <Form.Control.Feedback type="invalid">
                  {errors.notes}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="form-group">
              <Button type="submit" className="apply">
                {isSubmitting ? 'Sending...' : 'Send'}
              </Button>
            </Form.Group>
          </Form>
        )}
      </Formik>
      {message.show && (
        <div className="form-message">
          {message.type === 'danger' && (
            <div>
              <img src={successIcon} alt="success" />
              <span className="h5">Error!</span>
              <p>{message.text}</p>
            </div>
          )}
          {message.type !== 'danger' && (
            <div>
              <img src={successIcon} alt="success" />
              <span className="h5">Thank you!</span>
              <p>Your submission has been sent.</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
})`

  .form-message {
    padding: 100px 0 0;

    img {
      display: block;
      margin-bottom: 30px;
    }

    span.h5 {
      color: #231e60;
      font-family: Changa One;
      font-style: normal;
      font-weight: normal;
      font-size: 48px;
      line-height: 51px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      font-feature-settings: 'pnum' on, 'lnum' on;
      margin-bottom: 20px;
    }

    p {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 36px;
    }
  }

  .form-group {
    margin-bottom: 28px;

    @media (min-width: 768px) {
      &.short-input-right {
        padding-right: 7.5px !important;
      }

      &.short-input-left {
        padding-left: 7.5px !important;
      }
    }

    .form-label {
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: 'pnum' on, 'lnum' on;

      @media (min-width: 768px) {
        margin-left: 10px;
      }

      span {
        font-weight: 400;
      }
    }

    .special-label {
      display: none;
    }

    input,
    textarea,
    select {
      border: 1px solid #a6a6a6;
      border-radius: 1px;
      color: #141414;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      font-feature-settings: 'pnum' on, 'lnum' on;
      height: 42px;
      padding: 0 25px;

      &[type='tel'] {
        padding: 18.5px 14px 18.5px 58px;
        width: 100%;

        &.invalid-number {
          background-image: none !important;
          background: #ffe5e7;
          border: 1px solid #ff000d !important;
          color: #ff000d !important;

          ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #ff000d;
            opacity: 1; /* Firefox */
          }

          :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: #ff000d;
          }

          ::-ms-input-placeholder {
            /* Microsoft Edge */
            color: #ff000d;
          }
        }
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #a6a6a6;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #a6a6a6;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #a6a6a6;
      }
    }

    select {
      color: #a6a6a6;
    }

    textarea {
      height: 88px;
      padding: 16px 24px;
    }

    button {
      background: #63a536;
      border-radius: 4px;
      border: none;
      color: #fff;
      display: block;
      font-family: Titillium Web;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 28px;
      padding: 16px 48px;
      text-align: center;
      transition: 0.25s;
      margin-bottom: 40px;
      margin-top: 40px;
      margin-left: auto;
      text-transform: uppercase;
      width: auto;

      @media (min-width: 768px) {
        margin-left: 0;
      }

      &:hover,
      &:focus {
        box-shadow: inset 0 -5em 0 0 #231e60;
        color: #fff;
        text-decoration: none;
      }
    }
  }
`

export default FormBox
