import React from 'react'
// compositions
import Layout from '@compositions/Layout'
import Cannabis from '@compositions/Cannabis'

import ThemeProvider from '@providers/themeProvider'

const CannabisPage = () => {
  return (
    <ThemeProvider>
      <Layout page="cannabis">
        <Cannabis />
      </Layout>
    </ThemeProvider>
  )
}
export default CannabisPage
