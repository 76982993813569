import React from "react";
import styled from "@emotion/styled";

import Seo from "./components/Seo";
import Hero from "./components/Hero";
import IntroText from "./components/Intro-Text";
import SectionArea from "./components/SectionArea";
import Avantage from "./components/Avantage";
import BeforeAndAfter from "./components/BeforeAndAfter";
import Strategy from "./components/Strategy";
import Certified from "./components/Certified";
import FormArea from "./components/FormArea";
import Steps from "./components/Steps";
import Banner from "./components/Banner";
import ServiceAreas from "./components/ServiceAreas";

const Cannabis = styled(({ className }) => {
  return (
    <div className={className}>
      <Seo />
      <Hero />
      <IntroText />
      <SectionArea />
      <Avantage />
      <BeforeAndAfter />
      <Strategy />
      <Certified />
      <Steps />
      <Banner />
      <ServiceAreas />
      <FormArea />
    </div>
  );
})`
  margin: 129px auto 0;
  overflow: hidden;

  @media (min-width: 768px) {
    margin: 145px auto 0;
  }

  @media (min-width: 1200px) {
    margin: 160px auto 0;
  }
`;

export default Cannabis;
