import { useStaticQuery, graphql } from "gatsby";

export const BeforeAndAfterDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allBeforeAndAfterDataJson, images } = useStaticQuery(
    graphql`
      query queryBeforeAndAfterDataContent {
        allBeforeAndAfterDataJson {
          edges {
            node {
              title
              subtitle
              items {
                title
              }
            }
          }
        }
        images: allFile(
          sort: { fields: name, order: ASC }
          filter: { relativeDirectory: { eq: "cannabis/before-after" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 1000, quality: 75) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `
  );

  return [
    allBeforeAndAfterDataJson.edges[0].node.title,
    allBeforeAndAfterDataJson.edges[0].node.subtitle,
    allBeforeAndAfterDataJson.edges[0].node.items,
    images.edges,
  ];
};
