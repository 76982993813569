import { useStaticQuery, graphql } from 'gatsby'

export const CannabisFormAreaDataContent = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { allCannabisFormAreaDataJson } = useStaticQuery(
    graphql`
      query queryCannabisFormAreaDataContent {
        allCannabisFormAreaDataJson {
          edges {
            node {
              form {
                title
                content
              }
            }
          }
        }
      }
    `
  )
  return [allCannabisFormAreaDataJson.edges[0].node.form]
}
